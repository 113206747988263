import React from "react"
import { Link } from "gatsby"

import Navigation from "./Navigation"

const Header = () => {
  return (
    <header className="header">
      <div className="container header__inner">
        <Link to="/" className="logo">Christian Books</Link>
        <Navigation />
      </div>
    </header>
  )
}

export default Header
