import React from "react"
import { Link } from "gatsby"

const Navigation = () => {
  return (
    <nav className="navigation">
      <ul>
        <li><Link to="/#books">Books</Link></li>
        <li> <Link to="/#authors">Authors</Link></li>
      </ul>
    </nav>
  )
}

export default Navigation;
