import React from "react";
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import List from "../components/List";


const AuthorTemplate = ({ data }) => {
  const { authors } = data;
  const { title, bio, birth_date, relationships, external_links } = authors;
  const { christian_book } = relationships

  return (
    <Layout>

      <section className="author__title--wrapper">
        <h1>{title}</h1>
        <div>{birth_date}</div>
      </section>

      <section className="books--authors container">
        <h2>Books</h2>
        <div className="book__list">
          {
            christian_book
              ? <List list={christian_book} rootUrl={'book'} />
              : "No books yet for this author."
          }
        </div>
      </section>

      <section className="grid container">
        <div className="content">
          <h2>Bio</h2>
          <div dangerouslySetInnerHTML={{ __html: bio.value }}></div>
        </div>

        <div>
          <h3>External Links</h3>
          {
            external_links
              ? external_links.map(({ title, uri, index }) => {
                return (
                  <a key={index} href={uri}>
                    {title}
                  </a>)
              })
              : "No external Links"
          }
        </div>

      </section>


    </Layout>

  )
}

export default AuthorTemplate;

export const query = graphql`
query($id: String!) {
    authors(drupal_id: { eq: $id }) {
      title
      drupal_id
      drupal_internal__nid
      birth_date
      bio {
        value
      }
      external_links {
        title
        uri
      }
      relationships {
        christian_book {
          title
          drupal_internal__nid
        }
      }
    }
}
`
