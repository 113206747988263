import React from 'react'

const Footer = () => {
  return <footer className="footer">
    <div className="inner">
      <p>&copy;2020 <a href="https://liferealm.org">LifeRealm</a></p>
    </div>
  </footer>
}

export default Footer
