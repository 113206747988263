import React from 'react'
import { Link } from 'gatsby';

const List = ({ list, rootUrl }) => (

  list.map(({ drupal_internal__nid, title }) => {
    // Create slug from title by replacing spaces with dashes then delete non-word
    // chars except hyphens and turn to lowercase. 
    const transformedTitle = title.replace(/\s+/g, '-').replace(/[^a-zA-Z-]/g, '').toLowerCase();
    const slug = `${transformedTitle}-${drupal_internal__nid}`;

    return (
      <Link key={drupal_internal__nid} to={`/${rootUrl}/${slug}/`}>
        <span>{title}</span>
      </Link>
    )
  })

)

export default List;
