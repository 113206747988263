import React from 'react'
import { RefTagger } from 'react-reftagger';

// --- Import Fonts ---
import "fontsource-aleo"
import "fontsource-assistant"
import "fontsource-ostrich-sans-rounded"
import "fontsource-lato/300.css"
import "fontsource-lato/400.css"
import "fontsource-merriweather/300.css"

//-- Import global styles--
import '../sass/styles.scss'

import Header from '../components/Header'
import Footer from '../components/Footer'

export default function Layout({ children }) {
  return (
    <div className="palette">
      <Header />

      <main>
        <div className="content">
          {children}
        </div>

      </main>

      <Footer />
      <RefTagger />
    </div>
  )
}
